import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPost } from "utils/fetch-webapi";
import { isValidInt } from "utils/input-sanitize";

export const getFranchiseeNewsById = createAsyncThunk(
  "franchiseeNews/get",
  async (args) => {
    const { id, setAcknowledged } = args;
    if (isValidInt(id)) {
      const [, response] = await fetchPost("/news-item", {
        id,
      });

      setAcknowledged(response && response.isAcknowledged);
      return response;
    } else {
      throw Error("Invalid id : " + id);
    }
  }
);

export const getAllFranchiseeNewsList = createAsyncThunk(
  "franchiseeNewsAll/get",
  async (args) => {
    const { searchYear, loadContent, pageNo, rowsPerPage = 10 } = args;
    const [, response] = await fetchPost("/franchisee-news", {
      searchYear,
      loadContent,
      pageNo,
      rowsPerPage,
    });
    return response;
  }
);

export const updateFranchiseeNewsAcknowledge = createAsyncThunk(
  "franchiseeNewsAll/update",
  async (args) => {
    const { id } = args;
    const [, response] = await fetchPost("/acknowledge-news", {
      franchiseeNewsId: id,
    });

    return response;
  }
);

const initialState = {
  loading: false,
  error: null,
  news: null,
  loadingContents: false,
  newsList: null,
  newsNotFound: false,
};

const franchiseeNewsSlice = createSlice({
  name: "franchiseeNews",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getFranchiseeNewsById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.newsNotFound = false;
      })
      .addCase(getFranchiseeNewsById.fulfilled, (state, action) => {
        state.loading = false;
        state.news = action.payload;
        state.newsNotFound =
          action.payload === "" ||
          action.payload === null ||
          action.payload === undefined ||
          action.payload.id === 0;
      })
      .addCase(getFranchiseeNewsById.rejected, (state, action) => {
        state.loading = false;
        state.newsNotFound = true;
        state.error = action.error ?? null;
      })
      .addCase(getAllFranchiseeNewsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllFranchiseeNewsList.fulfilled, (state, action) => {
        state.loading = false;
        state.newsList = action.payload;
      })
      .addCase(getAllFranchiseeNewsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error ?? null;
      })
      // and provide a default case if no other handlers matched
      // eslint-disable-next-line no-unused-vars
      .addDefaultCase((state, action) => {});
  },
});

export default franchiseeNewsSlice.reducer;

export const selectAllFranchiseeNews = (state) => state.franchiseeNewsSlice;
