// This library contains methods used to validate/sanitize
// values passed from the frontend to the back end API

// Check if value contains only digits (is a number)
const isNum = (value) => {
  return /^\d+$/.test(value);
};

// Check if value does not exceed API max smallint value (2^15-1)
export const isValidSmallInt = (value) => {
  if (isNum(value)) {
    const check = Math.abs(parseInt(value));
    return !isNaN(check) && Math.abs(check) <= 32767n;
  }
  return false;
};

// Check if value does not exceed API max int value (2^31-1)
export const isValidInt = (value) => {
  if (isNum(value)) {
    const check = Math.abs(parseInt(value));
    return !isNaN(check) && Math.abs(check) <= 2147483647n;
  }
  return false;
};

// Check if value does not exceed API max bigint value (2^63-1)
export const isValidBigInt = (value) => {
  if (isNum(value)) {
    const check = Math.abs(parseInt(value));
    return !isNaN(check) && Math.abs(check) <= 9223372036854775807n;
  }
  return false;
};
