import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import CacheBuster from "react-cache-buster";
import { ErrorBoundary } from "react-error-boundary";

// mui-material
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

// third party
import { persistStore } from "redux-persist";
import { Provider, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

// defaultTheme
import theme from "./themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import Snackbar from "ui-component/extended/Snackbar";

import currentVer from "../package.json";

// localisation
import "./i18n";

// project imports
import * as serviceWorker from "./serviceWorker";
import configureAppStore from "./configureAppStore";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";

// style + assets
import "assets/scss/style.scss";
import "react-toastify/dist/ReactToastify.css";
import CignallLogo from "ui-component/CignallLogo";

//-----------------------|| REACT DOM RENDER  ||-----------------------//
const store = configureAppStore();
const persistor = persistStore(store);

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
const isProduction = process.env.NODE_ENV === "production";

function ErrorBoundaryFallback({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
      <button
        style={{ marginLeft: 10 }}
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
      >
        Go Back
      </button>
    </div>
  );
}

const Loader = () => (
  <div className="App">
    <CignallLogo />
    <div>Loading, one moment please...</div>
  </div>
);

function MainApp() {
  const customization = useSelector((state) => state.customization);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <App />
          <Snackbar />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

root.render(
  <Suspense fallback={<Loader />}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <CacheBuster
          currentVersion={currentVer.version}
          isEnabled={isProduction} //If false, the library is disabled.
          isVerboseMode={false} //If true, the library writes verbose logs to console.
          loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
          metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
        >
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <MainApp />
          </ErrorBoundary>
        </CacheBuster>
      </PersistGate>
      <ToastContainer
        autoClose={3000}
        hideProgressBar
        limit={10}
        newestOnTop={false}
        position="top-left"
        theme="colored"
      />
    </Provider>
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
