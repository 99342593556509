// action - state management
import * as actionTypes from "./customizationActions";

export const initialState = {
  fontFamily: `'Rubik', sans-serif`,
  borderRadius: 12,
  outlinedFilled: true,
  themeMode: "light", //dark
  presetColor: "default", // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: navigator.language,
  localeFormat: Intl.DateTimeFormat().resolvedOptions().locale,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  rtlLayout: false,
};

//-----------------------|| CUSTOMIZATION REDUCER ||-----------------------//

const customizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.THEME_MODE:
      return {
        ...state,
        themeMode: action.themeMode,
      };
    case actionTypes.PRESET_COLORS:
      return {
        ...state,
        presetColor: action.presetColor,
      };
    case actionTypes.LOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    case actionTypes.THEME_RTL:
      return {
        ...state,
        rtlLayout: action.rtlLayout,
      };
    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    case actionTypes.SET_OUTLINED_FILLED:
      return {
        ...state,
        outlinedFilled: action.outlinedFilled,
      };
    case actionTypes.LOCALE_FORMAT:
      return {
        ...state,
        localeFormat: action.localeFormat,
      };
    case actionTypes.TIMEZONE:
      return {
        ...state,
        timezone: action.timezone,
      };
    default:
      return state;
  }
};

export default customizationReducer;
