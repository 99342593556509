import { fetchGet, fetchPost, fetchPut } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";
import { toast } from "react-toastify";

const requestAllCategories = "REQUEST_ALL_CATEGORIES";
const responseAllCategories = "RESPONSE_ALL_CATEGORIES";
export const updateMyStoreTabIndex = "UPDATE_MYSTORE_TABINDEX";
export const responseCategoryByCategoryId = "RESPONSE_CATEGORY_BY_CATEGORY_ID";

const requestStoresByState = "REQUEST_STORES_BY_STATE";
const responseStoresByState = "RESPONSE_STORES_BY_STATE";

export const requestOpeningHours = "REQUEST_OPENING_HOURS";
export const responseOpeningHours = "RESPONSE_OPENING_HOURS";

const requestGetBanners = "REQUEST_GET_BANNERS";
const responseGetBanners = "RESPONSE_GET_BANNERS";

const requestStoreByStoreId = "REQUEST_STORE_BY_STOREID";
const responseStoreByStoreId = "RESPONSE_STORE_BY_STOREID";

export const actionCreators = {
  getAllCategories: (storeId) => async (dispatch) => {
    try {
      dispatch({ type: requestAllCategories });
      const [, response] = await fetchGet("/categories");
      if (response && response.length > 0) {
        const [, response] = await fetchGet(`/categories/${storeId}`);
        dispatch({
          type: responseCategoryByCategoryId,
          payload:
            response && response.length > 0
              ? response.map((r) => r.categoryId)
              : response,
        });
      }
      dispatch({ type: responseAllCategories, payload: response });
    } catch (e) {
      console.log("Error while trying to request current user " + e);
    }
  },

  getStoreByStoreId: (storeId) => async (dispatch) => {
    try {
      dispatch({ type: requestStoreByStoreId });
      const [, response] = await fetchGet(`/store/${storeId}`);
      dispatch({ type: responseStoreByStoreId, payload: response });
    } catch (e) {
      console.log("Error while trying to request store by id " + e);
    }
  },

  updateOpeningHours: (values, setSubmitting) => async (dispatch) => {
    try {
      const [, response] = await fetchPut("/mystore/opening-hours", {
        ...values,
      });

      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: `${
          response && response.errorNo && response.errorNo > 0
            ? "error"
            : "success"
        }`,
      });
      setSubmitting(false);
    } catch (e) {
      console.log("Error while updating the opening hours ", e);
      setSubmitting(false);
    }
  },

  updateStoreCategory: (storeId, categories) => async (dispatch) => {
    try {
      const [, response] = await fetchPut("/mystore/categories", {
        storeId,
        categories,
      });
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: response.message,
        variant: "alert",
        alertSeverity: `${
          response && response.errorNo && response.errorNo > 0
            ? "error"
            : "success"
        }`,
      });
    } catch (e) {
      console.log("Error while updating the store category ", e);
    }
  },

  getStoresOnlyByState: (state) => async (dispatch) => {
    try {
      dispatch({ type: requestStoresByState });
      const [, response] = await fetchGet(`/stores/state/${state}`);
      dispatch({ type: responseStoresByState, payload: response });
    } catch (e) {
      console.log("Error while trying to fetch store by state", e);
    }
  },

  createClubCignallMember:
    (
      values,
      setSubmitting,
      resetForm,
      setShowSuccessModal,
      setName,
      setMemberNumber
    ) =>
    async (dispatch) => {
      try {
        const [, response] = await fetchPost("/mystore/clubcignall", {
          ...values,
        });
        if (response && response.errorNo === 0) {
          setName(`${values.firstName}${" "}${values.surName}`);
          setMemberNumber(response.extraValue);
          setShowSuccessModal(true);
          resetForm();
        }
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${
            response && response.errorNo && response.errorNo > 0
              ? "error"
              : "success"
          }`,
        });

        if (response.extras.length > 0 && response.extras[0].errorNo !== 0) {
          toast.error(response.extras[0].message);
        }
        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
        console.log("Error while creating the Club Cignall member", e);
      }
    },

  getBanners: () => async (dispatch) => {
    dispatch({ type: requestGetBanners });
    try {
      const [, response] = await fetchGet("/mystore/banners");
      dispatch({
        type: responseGetBanners,
        payload: response,
      });
    } catch (e) {
      console.log("Error getting banners " + e);
    }
  },
};

const initialState = {
  gettingCategories: false,
  categories: null,
  selectedCategories: [],
  gettingStores: false,
  gettingOpeningHours: false,
  openingHours: null,
  stores: null,
  gettingBanners: true,
  banners: [],
  tabIndex: 0,
  selectedStore: null,
  gettingStore: false,
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestStoreByStoreId:
      return { ...state, gettingStore: true };
    case responseStoreByStoreId:
      return { ...state, gettingStore: false, selectedStore: payload };
    case requestAllCategories:
      return { ...state, gettingCategories: true };
    case responseAllCategories:
      return { ...state, gettingCategories: false, categories: payload };
    case responseCategoryByCategoryId:
      return { ...state, selectedCategories: [...payload] };
    case requestStoresByState:
      return { ...state, gettingStores: true, stores: null };
    case responseStoresByState:
      return { ...state, gettingStores: false, stores: payload };
    case requestOpeningHours:
      return { ...state, gettingOpeningHours: true };
    case responseOpeningHours:
      return { ...state, gettingOpeningHours: false, openingHours: payload };
    case updateMyStoreTabIndex:
      return { ...state, tabIndex: payload };
    case requestGetBanners:
      return { ...state, gettingBanners: true, banners: [] };
    case responseGetBanners:
      return { ...state, gettingBanners: false, banners: payload };
    default:
      return state;
  }
};

//OPTIONAL: PLEASE COPY THIS TEMPLATE TO ANY NEW ACTION REDUCER YOU ARE CREATING.
