import React from "react";
import { Link as RouterLink } from "react-router-dom";

// mui-material
import { ButtonBase } from "@mui/material";

// project imports
import config from "./../../../config";
import CignallLogo from "ui-component/CignallLogo";

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
  return (
    <ButtonBase disableRipple component={RouterLink} to={config.defaultPath}>
      {/*<Logo />*/}
      <CignallLogo />
    </ButtonBase>
  );
};

export default LogoSection;
