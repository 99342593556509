// mui-icons
import { NewspaperOutlined as IconNews } from "@mui/icons-material";
import { routePaths } from "routes/Paths";

//NOTE: URL and id of the children should have the same name
export const other = {
  id: "other",
  type: "group",
  children: [
    {
      id: "franchisee-news",
      title: "News",
      type: "item",
      url: routePaths.FranchiseeNews,
      icon: IconNews,
      breadcrumbs: false,
      chip: {
        label: "0",
        color: "primary",
        size: "small",
      },
    },
  ],
};
//NOTE: URL and id of the children should have the same name
