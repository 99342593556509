import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import navigationReducer from "./navigationReducer";
import snackbarReducer from "./snackbarReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as Authentication from "./authenticationActionReducer";
import * as MyStoresReducer from "./myStoresActionReducer";
import * as PersistantReducer from "./persistantReducer";
import * as DashboardReducer from "./dashboardReducer";
import * as ConsumerTargetReducer from "./consumerTargetActionReducer";
import franchiseeNewsReducer from "./franchiseeNewsSlice";
import franchiseePostReducer from "./franchiseePostsSlice";
import myStaffActionReducer from "./myStaffSlice";
import franchiseePostsFilterReducer from "./franchiseePostsFilterReducer";

//-----------------------|| COMBINE REDUCER ||-----------------------//

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["persistent"],
};

const reducer = combineReducers({
  customization: customizationReducer,
  navigation: navigationReducer,
  snackbar: snackbarReducer,
  authentication: Authentication.reducer,
  persistent: PersistantReducer.reducer,
  franchiseePosts: franchiseePostReducer,
  mystaff: myStaffActionReducer,
  franchiseeNews: franchiseeNewsReducer,
  mystores: MyStoresReducer.reducer,
  dashboard: DashboardReducer.reducer,
  customerTargets: ConsumerTargetReducer.reducer,
  franchiseePostsFilter: franchiseePostsFilterReducer,
});

export default persistReducer(persistConfig, reducer);
