// action - state management
import { MENU_OPEN, SET_MENU } from "./navigationActions";

export const initialState = {
  isOpen: [], //for active default menu
  opened: true,
};

//-----------------------|| CUSTOMIZATION REDUCER ||-----------------------//

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_OPEN: {
      const id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    }
    case SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    default:
      return state;
  }
};

export default navigationReducer;
