import { fetchGet } from "utils/fetch-webapi";

const types = {
  getDashboardDataRequest: "GET_DASHBOARD_DATA_REQUEST",
  getDashboardDataResponse: "GET_DASHBOARD_DATA_RESPONSE",
};

export const actionCreators = {
  getDashboardData: (storeId) => async (dispatch) => {
    dispatch({ type: types.getDashboardDataRequest });
    try {
      const [, response] = await fetchGet(
        `/franchisee-dashboard-v2/${storeId}`
      );

      dispatch({
        type: types.getDashboardDataResponse,
        payload: response,
      });
    } catch (e) {
      console.log("Error while trying to get dashboard data " + e);
    }
  },
};

const initialState = {
  loading: true,
  data: null,
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;

  switch (type) {
    case types.getDashboardDataRequest:
      return {
        ...state,
        loading: true,
      };
    case types.getDashboardDataResponse:
      return {
        ...state,
        loading: false,
        data: payload,
      };
    default:
      return state;
  }
};
