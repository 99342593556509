// action - customization reducer
export const LOCALE = "@customization/LOCALE";
export const LOCALE_FORMAT = "@customization/LOCALE_FORMAT";
export const PRESET_COLORS = "@customization/PRESET_COLORS";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";
export const SET_OUTLINED_FILLED = "@customization/SET_OUTLINED_FILLED";
export const THEME_MODE = "@customization/THEME_MODE";
export const THEME_RTL = "@customization/THEME_RTL";
export const TIMEZONE = "@customization/TIMEZONE";
