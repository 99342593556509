import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import qs from "query-string";
// routes
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";

//-----------------------|| ROUTING RENDER ||-----------------------//
const AppRoutes = () => {
  const location = useLocation();
  const urlList = [""];
  const urlLogin = "/login";
  const { currentAdmin } = useSelector((state) => state.authentication);

  const loggedIn = () => {
    return (
      currentAdmin &&
      typeof currentAdmin === "object" &&
      currentAdmin.identity !== 0
    );
  };
  const val = qs.parse(location.search);

  if (
    loggedIn() &&
    val &&
    val.redirect &&
    location.search.startsWith("?redirect")
  ) {
    return <Navigate to={val.redirect} replace />;
  }

  const showLoginRoutes = () => {
    if (!loggedIn()) {
      return true;
    } else if (loggedIn() && location.pathname.startsWith(urlLogin)) {
      return false;
    }

    return false;
  };

  return (
    <React.Fragment>
      {showLoginRoutes() ? <LoginRoutes /> : <MainRoutes />}
    </React.Fragment>
  );
};

export default AppRoutes;
