import React, { lazy } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import { routePaths } from "routes/Paths";

const LazyUserPreferences = Loadable(
  lazy(() => import("views/app-components/UserPreferences"))
  //lazy(() => import("views/application/users/account-profile/Profile1"))
);

// my stores related components
const MyStoreIndexPage = Loadable(
  lazy(() => import("views/app-components/MyStores/index"))
);
const MyStaffListPage = Loadable(
  lazy(() => import("views/app-components/MyStaff/MyStaffList"))
);
const MyStaffAdd = Loadable(
  lazy(() => import("views/app-components/MyStaff/MyStaffAdd"))
);
const MemberLookup = Loadable(
  lazy(() => import("views/app-components/MemberLookup"))
);

const FranchiseeNewsList = Loadable(
  lazy(() => import("views/app-components/FranchiseeNews"))
);

const FranchiseeNewsDetails = Loadable(
  lazy(() => import("views/app-components/FranchiseeNews/NewsDetails"))
);

const FranchiseePostList = Loadable(
  lazy(() => import("views/app-components/FranchiseePosts"))
);

const FranchiseePostDetails = Loadable(
  lazy(() => import("views/app-components/FranchiseePosts/PostDetails"))
);

// const FranchiseeVideoDetails = Loadable(
//   lazy(() => import("views/app-components/Videos"))
// );

function PageNotFound() {
  return <h1>404 - Page Not Found</h1>;
}

//-----------------------|| MAIN ROUTING ||-----------------------//

function MainRoutes() {
  const { currentAdmin } = useSelector((state) => state.authentication);
  if (!currentAdmin) {
    return null;
  }

  return renderLayout(currentAdmin);
}

function renderLayout(currentAdmin) {
  //
  const isAdmin =
    currentAdmin &&
    currentAdmin.franchisee &&
    !currentAdmin.franchisee.createdBy;

  const hasStores =
    currentAdmin &&
    currentAdmin.franchisee &&
    currentAdmin.franchisee.totalStore > 0;

  const isStaff =
    currentAdmin &&
    currentAdmin.franchisee &&
    currentAdmin.franchisee.createdBy;

  return (
    <MainLayout>
      <Routes>
        {/* <AuthGuard> */}
        {/* My stores */}

        {(isAdmin || isStaff) && hasStores ? (
          <Route path={routePaths.MyStores} element={<MyStoreIndexPage />} />
        ) : null}

        {/* My staff */}
        {isAdmin ? (
          <>
            <Route path={routePaths.MyStaff} element={<MyStaffListPage />} />
            <Route
              path={routePaths.MyStaff + "/add"}
              element={<MyStaffAdd />}
            />
            <Route
              path={routePaths.MyStaff + "/edit/:franchiseeId"}
              element={<MyStaffAdd />}
            />
          </>
        ) : null}

        {/* Member lookup */}
        {isAdmin ? (
          <Route path={routePaths.MemberLookup} element={<MemberLookup />} />
        ) : null}

        {/* Franchisee news */}
        {isAdmin || isStaff ? (
          <>
            <Route
              path={routePaths.FranchiseeNews}
              element={<FranchiseeNewsList />}
            />
            <Route
              path={routePaths.FranchiseeNews + "/view/:id"}
              element={<FranchiseeNewsDetails />}
            />
          </>
        ) : null}

        {/* Franchisee content */}
        {isAdmin || isStaff ? (
          <>
            <Route
              path={routePaths.FranchiseePosts}
              element={<FranchiseePostList />}
            />
            <Route
              path={routePaths.FranchiseePosts + "/view/:id"}
              element={<FranchiseePostDetails />}
            />
          </>
        ) : null}

        {/* Video content */}
        {/* {isAdmin || isStaff ? (
          <>
            <Route path="/videos" element={<FranchiseeVideoDetails />} />
          </>
        ) : null} */}

        {/* My Profile */}
        {isAdmin || isStaff ? (
          <Route
            path={routePaths.UserPreferences}
            element={<LazyUserPreferences />}
          />
        ) : null}
        <Route
          path="/"
          element={<Navigate to={routePaths.MyStores} replace />}
        />
        <Route
          path="/login"
          element={<Navigate to={routePaths.MyStores} replace />}
        />
        <Route path="*" element={<PageNotFound />} />
        {/* </AuthGuard> */}
      </Routes>
    </MainLayout>
  );
}

export default MainRoutes;
