import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchGet, fetchPost, fetchPut } from "utils/fetch-webapi";
import { isValidInt } from "utils/input-sanitize";

export const getAllStaff = createAsyncThunk("myStaff/getAll", async (args) => {
  const { searchTerm, pageNo, rowsPerPage = 10 } = args;
  const [, response] = await fetchPost("/staffs", {
    searchTerm,
    pageNo,
    rowsPerPage,
  });
  return response;
});

export const getFranchiseeById = createAsyncThunk(
  "myStaff/getFranchiseeById",
  async (args) => {
    const { staffFranchiseeId, ownerFranchiseeId } = args;
    if (isValidInt(staffFranchiseeId)) {
      const [, response] = await fetchGet(
        `/staff/${staffFranchiseeId}/${ownerFranchiseeId}`
      );
      return response;
    } else {
      throw Error("Invalid id : " + staffFranchiseeId);
    }
  }
);

export const getStaffLogin = createAsyncThunk(
  "myStaff/mystafflogin",
  async () => {
    const [, response] = await fetchGet("/mystaff-login");
    return response;
  }
);

export const createFranchisee = createAsyncThunk(
  "myStaff/createFranchisee",
  async (args) => {
    const { values } = args;
    const [, response] = await fetchPost("/staff", {
      ...values,
      mobileNumber: values.mobileNumber.replaceAll("-", ""),
      status: values.status === "Enable",
    });

    return response;
  }
);

export const updateFranchisee = createAsyncThunk(
  "myStaff/updateFranchisee",
  async (args) => {
    const { values } = args;

    const [, response] = await fetchPut("/staff", {
      ...values,
      mobileNumber: values.mobileNumber.replaceAll("-", ""),
      status: values.status === "Enable",
    });

    return response;
  }
);

export const updateFranchiseePassword = createAsyncThunk(
  "myStaff/updateFranchiseePassword",
  async (args) => {
    const { franchiseeId, password } = args;
    const [, response] = await fetchPost("/staff/password-reset", {
      franchiseeId,
      password,
    });

    return response;
  }
);

const initialState = {
  loadingStaff: false,
  staffUsers: null,
  gettingStaffLogin: false,
  gettingStaff: false,
  franchiseeDetails: null,
  staffNotFound: false,
  selectedStores: [],
  loginId: null,
};

const myStaffSlice = createSlice({
  name: "myStaff",
  initialState,
  // reducers: (create) => ({
  //   updateSelectedfranchiseeStores: create.reducer((state, action) => {
  //     state.selectedStores = action.payload;
  //   }),
  // }),
  reducers: {
    updateSelectedfranchiseeStores(state, action) {
      state.selectedStores = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllStaff.pending, (state) => {
        state.loadingStaff = true;
        state.error = null;
      })
      .addCase(getAllStaff.fulfilled, (state, action) => {
        state.loadingStaff = false;
        state.staffUsers = action.payload;
      })
      .addCase(getAllStaff.rejected, (state, action) => {
        state.loadingStaff = false;
        state.error = action.error ?? null;
      })
      .addCase(getFranchiseeById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFranchiseeById.fulfilled, (state, action) => {
        state.loading = false;
        state.staffNotFound =
          action.payload === null || action.payload.franchisee === undefined;
        state.franchiseeDetails = action.payload;
        state.selectedStores = action.payload.franchiseeStores.map(
          (franchisee) => franchisee.store.storeId
        );
      })
      .addCase(getFranchiseeById.rejected, (state, action) => {
        state.loading = false;
        state.staffNotFound = true;
        state.error = action.error ?? null;
      })
      .addCase(getStaffLogin.pending, (state) => {
        state.gettingStaffLogin = true;
        state.error = null;
      })
      .addCase(getStaffLogin.fulfilled, (state, action) => {
        state.gettingStaffLogin = false;
        state.loginId = action.payload;
      })
      .addCase(getStaffLogin.rejected, (state, action) => {
        state.gettingStaffLogin = false;
        state.error = action.error ?? null;
      })
      .addCase(updateFranchisee.pending, (state) => {
        state.error = null;
      })
      .addCase(updateFranchisee.fulfilled, (state, action) => {
        state.error = action.error ?? null;
      })
      .addCase(updateFranchisee.rejected, (state, action) => {
        state.error = action.error ?? null;
      })
      // and provide a default case if no other handlers matched
      // eslint-disable-next-line no-unused-vars
      .addDefaultCase((state, action) => {});
  },
});

export default myStaffSlice.reducer;

export const { updateSelectedfranchiseeStores } = myStaffSlice.actions;
