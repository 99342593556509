import PropTypes from "prop-types";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

// project imports
import useAuth from "hooks/useAuth";
import config from "../../config";

//-----------------------|| GUEST GUARD ||-----------------------//

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */
function GuestGuard() {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? <Navigate to={config.defaultPath} /> : <Outlet />;
}

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
