// mui-icons
import {
  StorefrontOutlined as IconMyStores,
  PeopleOutlined as IconMyStaff,
  SearchOutlined as IconMemberLookup,
  NewspaperOutlined as IconNews,
  ArticleOutlined as IconPost,
} from "@mui/icons-material";

// constants
import { routePaths } from "routes/Paths";

//NOTE: URL and id of the children should have the same name
export const admin = {
  id: "admins",
  type: "group",
  children: [
    {
      id: "my-stores",
      title: "My Stores",
      type: "item",
      url: routePaths.MyStores,
      icon: IconMyStores,
      breadcrumbs: false,
    },
    {
      id: "my-staff",
      title: "My Staff",
      type: "item",
      url: routePaths.MyStaff,
      icon: IconMyStaff,
      breadcrumbs: false,
    },
    {
      id: "member-lookup",
      title: "Member Lookup",
      type: "item",
      url: routePaths.MemberLookup,
      icon: IconMemberLookup,
      breadcrumbs: false,
    },
    {
      id: "franchisee-news",
      title: "News",
      type: "item",
      url: routePaths.FranchiseeNews,
      icon: IconNews,
      breadcrumbs: false,
      chip: {
        label: "0",
        color: "primary",
        size: "small",
      },
    },
    {
      id: "franchisee-posts",
      title: "Articles & Posts",
      type: "item",
      url: routePaths.FranchiseePosts,
      icon: IconPost,
      breadcrumbs: false,
    },
  ],
};
//NOTE: URL and id of the children should have the same name
