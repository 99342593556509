import { fetchGet, fetchPost } from "utils/fetch-webapi";
import { SNACKBAR_OPEN } from "./navigationActions";

const requestCategories = "REQUEST_CATEGORIES";
const responseCategories = "RESPONSE_CATEGORIES";
const requestBroadcastMessages = "REQUEST_BROADCAST_MESSAGE";
const responseBroadcastMessages = "RESPONSE_BROADCAST_MESSAGE";
const requestBroadcastMessageById = "REQUEST_BROADCAST_MESSAGE_BY_ID";
const responseBroadcastMessageById = "RESPONSE_BROADCAST_MESSAGE_BY_ID";

const requsetMembersPostcode = "REQUEST_MEMBERS_POSTCODE";
const responseMemberPostcode = "RESPONSE_MEMBERS_POSTCODE";

export const actionCreators = {
  getPostcodes: (storeId) => async (dispatch) => {
    try {
      dispatch({ type: requsetMembersPostcode });
      const [, response] = await fetchGet(
        `/notifications/postcodes?storeId=${storeId}`
      );
      dispatch({ type: responseMemberPostcode, payload: response });
    } catch (e) {
      console.log("Error while trying to requeset postcodes ", e);
    }
  },
  getStoreCategories: (storeId) => async (dispatch) => {
    try {
      dispatch({ type: requestCategories });
      const [, response] = await fetchGet(`/categories/${storeId}`);
      dispatch({ type: responseCategories, payload: response });
    } catch (e) {
      console.log("Error while trying to request category " + e);
    }
  },

  getAllBroadcastMessages:
    (
      franchiseeId,
      storeId,
      filterExpired,
      pageNo = 1,
      rowsPerPage = 10,
      dispatchLoader = true
    ) =>
    async (dispatch) => {
      if (dispatchLoader) {
        dispatch({ type: requestBroadcastMessages });
      }
      let url = `/notifications/BroadcastNotifications?filterExpired=${filterExpired}&storeId=${storeId}&franchiseeId=${franchiseeId}&pageNo=${pageNo}&rowsPerPage=${rowsPerPage}`;
      const [, response] = await fetchGet(url);
      dispatch({ type: responseBroadcastMessages, payload: response });
    },

  getBroadcastMessageById: (notificationId) => async (dispatch) => {
    dispatch({ type: requestBroadcastMessageById });
    let url = `/notifications/BroadcastNotification/${notificationId}`;
    const [, response] = await fetchGet(url);
    dispatch({ type: responseBroadcastMessageById, payload: response });
  },

  sendBroadcastMessage:
    (values, setSubmitting, resetForm) => async (dispatch) => {
      try {
        const [, response] = await fetchPost(
          "/notifications/BroadcastNotification",
          {
            Notification: {
              ...values,
              status: values.status === "Enable",
            },
            ConsumerTarget: values.consumerTarget
              ? values.consumerTarget
              : undefined,
            DryRun: false,
          }
        );

        if (response.errorNo === 0) {
          dispatch(
            actionCreators.getAllBroadcastMessages(
              values.franchiseeId,
              values.storeId,
              values.filterExpired
            )
          );
        }
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: response.message,
          variant: "alert",
          alertSeverity: `${
            response && response.errorNo && response.errorNo > 0
              ? "error"
              : "success"
          }`,
        });
        setSubmitting(false);
        resetForm();
      } catch (e) {
        setSubmitting(false);
      }
    },
};

const initialState = {
  gettingCategories: false,
  categories: null,
  loading: true,
  broadcastMessages: [],
  totalCount: 0,
  postcodes: [],
  categoryIds: [],
  message: null,
};

export const reducer = (state, { type, payload }) => {
  state = state || initialState;
  switch (type) {
    case requestCategories:
      return { ...state, gettingCategories: true };
    case responseCategories:
      return { ...state, gettingCategories: false, categories: payload };
    case requestBroadcastMessages:
      return {
        ...state,
        loading: true,
        broadcastMessages: initialState.broadcastMessages,
      };
    case responseBroadcastMessages:
      return {
        ...state,
        loading: false,
        broadcastMessages: payload.notifications,
        totalCount: payload.totalCount,
      };
    case requestBroadcastMessageById:
      return { ...state, loading: true };
    case responseBroadcastMessageById:
      return { ...state, loading: false, message: payload };
    case requsetMembersPostcode:
      return { ...state, loading: true };
    case responseMemberPostcode:
      return { ...state, postcodes: payload.postcodes };
    default:
      return state;
  }
};
