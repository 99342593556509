import { admin } from "./admin";
import { other } from "./other";
import { staff } from "./staff";

//-----------------------|| MENU ITEMS ||-----------------------//

export const menuItems = {
  items: [admin],
};

export const otherItems = {
  items: [other],
};

export const staffItems = {
  items: [staff],
};
