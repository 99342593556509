import PropTypes from "prop-types";
import React from "react";
import { Outlet } from "react-router-dom";

// mui-material
import {
  AppBar,
  CssBaseline,
  Toolbar,
  Typography,
  Grid,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// mui-icons
import { ChevronRight as IconChevronRight } from "@mui/icons-material";

// state management
import { useDispatch, useSelector } from "react-redux";

// third-party
import classnames from "classnames";

// project imports
import { drawerWidth, cignallThemeColor } from "themes/constants";
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
// import Customization from "layout/Customization";
import Header from "./Header";
import Sidebar from "./Sidebar";
//import NotificationBar from "./NotificationBar";
import { menuItems as navigation } from "menu-items";
import { SET_MENU } from "store/navigationActions";

const PREFIX = "MainLayout";

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  appBarWidth: `${PREFIX}-appBarWidth`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },

  [`& .${classes.appBar}`]: {
    //backgroundColor: theme.palette.background.default,
    backgroundColor: cignallThemeColor,
  },

  [`& .${classes.appBarWidth}`]: {
    transition: theme.transitions.create("width"),
    //backgroundColor: theme.palette.background.default,
    backgroundColor: cignallThemeColor,
  },

  [`& .${classes.content}`]: {
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginRight: "10px",
    },
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("lg")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "10px",
    },
  },
}));

export const envName = process.env.REACT_APP_ENV_NAME;
export const envVersion = process.env.REACT_APP_VERSION;

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children }) => {
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.navigation.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  return (
    <div>
      <Root className={classes.root}>
        <CssBaseline />
        {/* header */}
        <AppBar
          position="fixed"
          className={!leftDrawerOpened ? classes.appBarWidth : classes.appBar}
        >
          <Toolbar>
            <Header drawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBar>

        {/* drawer */}
        <Sidebar
          drawerOpen={leftDrawerOpened}
          drawerToggle={handleLeftDrawerToggle}
        />

        {/* main content */}
        <main
          className={classnames([
            classes.content,
            {
              [classes.contentShift]: leftDrawerOpened,
            },
          ])}
        >
          {/* breadcrumb */}
          <Breadcrumbs
            separator={IconChevronRight}
            navigation={navigation}
            icon
            title
            rightAlign
          />
          <div>{children}</div>
          <Grid item>
            <Typography
              variant="subtitle1"
              style={{
                bottom: 0,
                position: "fixed",
                width: `${!leftDrawerOpened ? "100%" : "80%"}`,
                textAlign: "center",
              }}
            >
              &copy; {new Date().getFullYear()}{" "}
              <Typography
                variant="subtitle1"
                component={Link}
                href="https://cignall.com.au/"
                target="_blank"
              >
                Cignall Pty Ltd
              </Typography>{" "}
              &nbsp; ({envName}) - {envVersion}
            </Typography>
          </Grid>
        </main>

        {/* notication bar */}
        {/* <NotificationBar /> */}

        {/*Setting to enable dark and light layout*/}
        {/* <Customization /> */}
        <Outlet />
      </Root>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
