export const genderList = [
  { key: "All", value: "All" },
  { key: "Male", value: "Male" },
  { key: "Female", value: "Female" },
  { key: "Prefer not to answer", value: "Prefer not to answer" },
];

export const stateList = [
  {
    label: "New South Wales",
    key: "NSW",
  },
  {
    label: "Victoria",
    key: "VIC",
  },
  {
    label: "Queensland",
    key: "QLD",
  },
  {
    label: "South Australia",
    key: "SA",
  },
  {
    label: "Western Australia",
    key: "WA",
  },
  {
    label: "Australian Capital Territory",
    key: "ACT",
  },
  {
    label: "Northern Territory",
    key: "NT",
  },
  {
    label: "Tasmania",
    key: "TAS",
  },
];

export const adminStatusList = ["Active", "Disabled"];

export const languageList = [
  {
    label: "Chinese Simplified",
    key: "zh-CN",
  },
  {
    label: "English - AU",
    key: "en-AU",
  },
  {
    label: "English - GB",
    key: "en-GB",
  },
  {
    label: "English - US",
    key: "en",
  },
  {
    label: "French",
    key: "fr",
  },
];

export const localeFormatList = [
  {
    label: "Chinese (Simplified, China)",
    key: "zh-CN",
  },
  {
    label: "English (Australia)",
    key: "en-AU",
  },
  {
    label: "English (United Kingdom)",
    key: "en-GB",
  },
  {
    label: "English (United States)",
    key: "en-US",
  },
  {
    label: "French",
    key: "fr",
  },
];

export const timezoneList = [
  // {
  //   label: "Asia - Kolkata",
  //   key: "Asia/Kolkata",
  // },
  {
    label: "Australia - Victoria",
    key: "Australia/Melbourne",
  },
  {
    label: "Australia - New South Wales (most areas)",
    key: "Australia/Sydney",
  },
  {
    label: "Australia - Queensland (most areas)",
    key: "Australia/Brisbane",
  },
  {
    label: "Australia - Tasmania",
    key: "Australia/Hobart",
  },
  {
    label: "Australia - Northern Territory",
    key: "Australia/Darwin",
  },
  {
    label: "Australia - South Australia",
    key: "Australia/Adelaide",
  },
  {
    label: "Australia - Western Australia (most areas)",
    key: "Australia/Perth",
  },
  // {
  //   label: "Europe - Athens",
  //   key: "Europe/Athens",
  // },
  // {
  //   label: "Europe - Rome",
  //   key: "Europe/Rome",
  // },
  // {
  //   label: "Europe - London",
  //   key: "Europe/London",
  // },
];
